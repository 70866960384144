import { getDefaultRegistry, IWidgetControlProps, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getlocaleText } from "../../utils/helper";

type LoadingIndicatorUIControlProps = IWidgetControlProps;

const LoadingIndicator = props => {
	const { isLoading, loadingMessage, showProgressBar, showCancelBtn, cancelFunction, progressValue, fileName } = props;
	let loaderDisplay: any = {
		width: "0%",
		display: "none",
	};
	if (isLoading) {
		loaderDisplay = {
			width: "100%",
			display: "flex",
			zIndex: 10000,
		};
	}
	const customClass = props.className ? `loadingOverlay ${props.className}` : "loadingOverlay";
	const customStyle = props.style ? { ...loaderDisplay, ...props.style } : { ...loaderDisplay };
	const [uploadingFileName, setUploadingFileName] = useState(fileName);
	const [uploadProgressValue, setUploadProgressValue] = useState(progressValue);

	useEffect(() => {
		const id = setInterval(timer, 100);
		return () => clearInterval(id);
	}, [uploadProgressValue]);

	const timer = () => {
		// console.log(`progressValue ${localStorage.getItem("progressValue")}`);
		setUploadProgressValue(localStorage.getItem("progressValue") ? Number(localStorage.getItem("progressValue")) : 0);
		setUploadingFileName(localStorage.getItem("fileName") ? localStorage.getItem("fileName") : fileName)
	}

	return (
		<div className={customClass} style={customStyle}>
			{showProgressBar ?
				<div className="row">
					<div className="lodingContent progressBarContent  col-md-10">
						{uploadingFileName && <label>{`${getlocaleText("{{Document.Filename}}")}: ${uploadingFileName} `}</label>}
						<ProgressBar animated now={uploadProgressValue} label={`${uploadProgressValue}%`} />
						{showCancelBtn && <button id="progressCancelBtn" className="freshui-primary-button btn btn-primary btn-sm" onClick={() => { console.log("cancel clicked"); cancelFunction(); }}>X</button>}
					</div> </div> :
				<Spinner animation="border" variant="primary" />}
			<div className="lodingContent">
				{loadingMessage}
			</div>
		</div>
	)
}

class LoadingIndicatorWidget extends React.Component<LoadingIndicatorUIControlProps, {}> {
	_getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}


	render() {
		const { loadingMessage, isLoading, className, style, showProgressBar, showCancelBtn, cancelFunction, progressValue, fileName } = this._getControlSchemaProperties();
		return (<LoadingIndicator
			isLoading={isLoading}
			loadingMessage={loadingMessage}
			{...this.props}
			className={className}
			style={style}
			showProgressBar={showProgressBar}
			showCancelBtn={showCancelBtn}
			cancelFunction={cancelFunction}
			progressValue={progressValue}
			fileName={fileName}
		/>)
	}
}

LoadingIndicatorWidget["displayName"] = "LoadingIndicatorWidget";

WidgetsFactory.instance.registerFactory(LoadingIndicatorWidget);
WidgetsFactory.instance.registerControls({
	loadingOverlay_control: "LoadingIndicatorWidget",
	"itsy:ui:loadingoverlay": "LoadingIndicatorWidget",
});
